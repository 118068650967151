<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form ref="form" @submit.prevent="submitForm">
              <div class="row">
                <div class="col-6">
                  <v-text-field
                    v-model="form.searchKeyword"
                    :disabled="datatable.loading"
                    :label="$t('SHARED.KEYWORD')"
                    :hint="$t('REPORT.CUSTOMER_DETAIL.KEYWORD_HINT')"
                    prepend-icon="mdi-file-document-box-search-outline"
                  ></v-text-field>
                </div>
                
                <div class="col-3">
                    <v-dialog
                      ref="dialogStart"
                      v-model="form.startDateModel"
                      :disabled="form.loading"
                      :return-value.sync="form.startDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.startDate"
                          :disabled="form.loading"
                          :label="$t('REPORT.USE_BELL_POINT.START_DATE')"
                          :rules="form.startDateRules"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.startDate"
                        locale="th"
                        :disabled="form.loading"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="default"
                          @click="form.startDateModel = false"
                          >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogStart.save(form.startDate)"
                          >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                </div>

                <!-- endDate -->
                <div class="col-3">
                  <v-dialog
                    ref="dialogEnd"
                    v-model="form.endDateModel"
                    :disabled="form.loading"
                    :return-value.sync="form.endDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.endDate"
                        :disabled="form.loading"
                        :label="$t('REPORT.USE_BELL_POINT.END_DATE')"
                        :rules="form.endDateRules"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.endDate"
                      locale="th"
                      :disabled="form.loading"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="default"
                        @click="form.endDateModel = false"
                        >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogEnd.save(form.endDate)"
                        >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </div>

                <div class="col-3">
                  <v-menu
                    ref="birthDateMenu"
                    v-model="form.birthDateMenu"
                    :disabled="datatable.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedBirthDateFormatted"
                        :disabled="datatable.loading"
                        :label="$t('REPORT.CUSTOMER_DETAIL.BIRTH_DATE')"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="form.birthDate" 
                      scrollable
                      :disabled="datatable.loading"
                      type="month"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="form.birthDateMenu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.birthDateMenu.save(form.birthDate)">OK</v-btn>
                    </v-date-picker>     
                  </v-menu>
                </div>

                <div class="col-3">
                  <v-autocomplete
                    v-model="form.levelMember"
                    :disabled="datatable.loading"
                    :items="form.memberClassCodeItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('REPORT.CUSTOMER_DETAIL.MEMBER_CLASS_CODE')"
                    :placeholder="
                      $t('SHARED.START_TYPING_TO_SEARCH')"
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.inActiveStatus"
                    :disabled="datatable.loading"
                    :items="form.inActiveStatusItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('REPORT.CUSTOMER_DETAIL.IN_ACTIVE_STATUS')"
                    :placeholder="
                      $t('SHARED.START_TYPING_TO_SEARCH')
                    "
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <v-btn
                    :disabled="datatable.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon v-if="!datatable.loading" left
                      >mdi-database-search</v-icon
                    >
                    <v-icon v-if="datatable.loading" left
                      >fa fa-spinner fa-spin</v-icon
                    >
                    {{ $t("SHARED.SEARCH_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="datatable.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="datatable.loading"
                    color="warning"
                    class="mr-4"
                    tile
                    @click.prevent="Export"
                  >
                    <v-icon v-if="!datatable.loading" left>fas fa-file-export</v-icon>
                    <v-icon v-if="datatable.loading" left>fa fa-spinner fa-spin</v-icon>
                    {{ $t("SHARED.EXPORT_BUTTON") }}
                  </v-btn>
                </div>
              </div>
            </v-form>

            <v-divider class="my-4"></v-divider>

            <v-data-table
              :headers="datatable.headers"
              :items="datatable.items"
              :loading="datatable.loading"
              :options.sync="datatable.options"
              :server-items-length="datatable.total"
              :footer-props="{
                'items-per-page-options': [30, 50, 100],
              }"
              multi-sort
            >
              <template v-slot:item.inActiveStatus="{ item }">
                <v-icon 
                  :class="getInActiveStatus(item.inActiveStatus)">
                  {{ getInActiveStatusIcon(item.inActiveStatus) }}
                </v-icon>
              </template>

              <template v-slot:item.memberCardFileByteUrl="{ item }">
                <v-img 
                    v-if="item.memberCardFileByteUrl"
                    :v-model="item.memberCardFileByteUrl"
                    :src="item.memberCardFileByteUrl" 
                    aspect-ratio="1.7" 
                    contain
                  >
                  </v-img>
              </template>
              
              <template v-slot:item.sumpoint="{ item }">
                {{numberWithCommas(item.sumpoint)}}
              </template>
              <template v-slot:item.balancePoint="{ item }">
                {{numberWithCommas(item.balancePoint)}}
              </template>
              
            </v-data-table>

            <v-dialog
              v-model="datatable.loading"
              persistent
              width="300"
            >
              <v-card>
                <v-card-title class="headline">
                  {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                >
                <v-card-text>
                  <p>
                    {{ $t("SHARED.PROCESSING") }}
                  </p>
                  <v-progress-linear
                    indeterminate
                    color="amber lighten-3"
                    class="mb-3"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>

          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import JwtService from "@/common/jwt.service";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import moment from "moment";

export default {
  components: {
    KTPortlet,
  },
  data() {
    return {
      form: {
        searchKeyword: "",
        birthDateMenu: false,
        birthDate: "",
        birthDateFormatted: "",

        startDate: null,
        endDate: null,
        endDateModel: false,
        startDateModel: false,

        levelMember: null,
        memberClassCodeItems: [
          {
            text: this.$t("SYS_VARIABLE.MEMBER_CLASS_NORMAL"),
            value: "MEMBER_CLASS_NORMAL",
          },
          {
            text: this.$t("SYS_VARIABLE.MEMBER_CLASS_GOLD"),
            value: "MEMBER_CLASS_GOLD",
          },
        ],
        inActiveStatus: null,
        inActiveStatusItems: [
          {
            text: this.$t("SYS_VARIABLE.IN_ACTIVE_STATUS_YES"),
            value: false,
          },
          {
            text: this.$t("SYS_VARIABLE.IN_ACTIVE_STATUS_NO"),
            value: true,
          },
        ],
      },
      datatable: {
        headers: [
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.CARD_NUMBER"),
            value: "cardNumber",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.CARD_AUTHEN"),
            value: "loginProvider",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.EMAIL"),
            value: "email",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.FIRST_NAME"),
            value: "firstName",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.LAST_NAME"),
            value: "lastName",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.GENDER"),
            value: "genderString",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.BIRTH_DATE"),
            value: "birthDateString",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.AGE"),
            value: "age",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.PHONE_NUMBER"),
            value: "phoneNumber",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.ADDRESS"),
            value: "address",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.BRANCH_CODE"),
            value: "branchCode",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.SALE_BRANCH"),
            value: "saleBranch",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.SUM_POINT"),
            value: "sumpoint",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.BALANCE_POINT"),
            value: "balancePoint",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.MEMBER_CLASS_CODE"),
            value: "memberCardFileByteUrl",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.IN_ACTIVE_STATUS"),
            value: "inActiveStatus",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.UP_LEVEL_DATE"),
            value: "upLevelDateString",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.SALE_DATE"),
            value: "saleDateString",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.REGISTERED_DATE"),
            value: "registeredDateString",
            align: "center",
          },
          {
            text: this.$t("REPORT.CUSTOMER_DETAIL.EXPIRE_DATE"),
            value: "expireDateString",
            align: "center",
          },
        ],
        items: [],
        total: 0,
        loading: true,
        options: {
          sortBy: ["cardNumber"],
          sortDesc: [false],
          itemsPerPage: 30,
        },
      },
    };
  },
  methods: {
    Export(){
      var SortBy = "";
      var SortDesc = "";

        this.datatable.options.sortBy.forEach((val) => {
          SortBy += '&SortBy='+val;
        });
        this.datatable.options.sortDesc.forEach((val) => {
          SortDesc += '&SortDesc='+val;
        });
      var LevelMember = this.form.levelMember
            ? this.form.levelMember.value
            : "";
      var InActiveStatus = this.form.inActiveStatus
            ? this.form.inActiveStatus.value
            : ""
      window.open("/Files/Export/CustomerDetail?searchKeyword="
      +this.form.searchKeyword
      +"&BirthDateString="+this.form.birthDateFormatted
      +"&RegisteredDateString="+this.form.registeredDateFormatted
      +"&StartDate="+this.form.startDate 
      +"&EndDate="+this.form.endDate
      +"&LevelMember="+LevelMember
      +"&InActiveStatus="+InActiveStatus
      + SortBy + SortDesc 
      +"&token="+this.token,"_blank");
    },
    submitForm() {
      this.getDataFromApi().then((data) => {
        this.datatable.total = data.total;
        this.datatable.items = data.items;
      });
    },
    resetForm() {
      this.form.searchKeyword = "";
      this.form.levelMember = null;
      this.form.inActiveStatus = null;
      this.form.birthDate = "";
      this.form.registeredDate = "";
      this.form.birthDateFormatted = "";
      // RESET START DATE
      var s = new Date();
      s.setDate(1);
      this.form.startDate = s.toISOString().substr(0, 10);
      var e = new Date();
      var year = new Date().getFullYear();
      var month = new Date().getMonth();
      month = month == 12 ? 0 : month + 1;
      var day = new Date(year, month, 0).getDate();
      e.setDate(day);
      this.form.endDate = e.toISOString().substr(0, 10);
      // RESET END DATE
      // this.$refs.form.reset();
      this.submitForm();
    },
    getDataFromApi() {
      this.datatable.loading = true;
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/Report/CustomerDetail/Search", {
          GroupBy: this.datatable.options.groupBy,
          GroupDesc: this.datatable.options.groupDesc,
          ItemsPerPage: this.datatable.options.itemsPerPage,
          Page: this.datatable.options.page,
          SortBy: this.datatable.options.sortBy,
          SortDesc: this.datatable.options.sortDesc,
          SearchKeyword: this.form.searchKeyword,
          BirthDateString: this.form.birthDateFormatted
            ? moment(this.form.birthDate, "YYYY-MM").endOf("month").format("DD/MM/YYYY")
            : null,
          StartDate: this.form.startDate,
          EndDate: this.form.endDate,
          LevelMember: this.form.levelMember
            ? this.form.levelMember.value
            : null,
          InActiveStatus: this.form.inActiveStatus
            ? this.form.inActiveStatus.value
            : null,    
        })
          .then(({ data }) => {
            resolve({
              items: data.items,
              total: data.total,
            });
          })
          .finally(() => {
            this.datatable.loading = false;
          });
      });
    },
    getInActiveStatus(inActiveStatus){
      if (!inActiveStatus) 
        return 'text-success';
      else 
        return 'text-danger';
    },
    getInActiveStatusIcon(inActiveStatus){
      if (!inActiveStatus) 
        return 'check_circle';
      else 
        return 'cancel';
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month] = date.split('-');
      return `${month}/${year}`;
    },
    numberWithCommas(num){
      if(num == null)
        return null;
      else
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.REPORT.SECTION"), route: "/Report" },
      { title: this.$t("MENU.REPORT.CUSTOMER_DETAIL") },
    ]);
  },
  computed: {
    title() {
      return this.$t("MENU.REPORT.CUSTOMER_DETAIL_SEARCH");
    },
    computedBirthDateFormatted() {
      return this.formatDate(this.form.birthDate)
    },
    token() {
      return JwtService.getToken();
    },
  },
  watch: {
    "datatable.options": {
      handler() {
        if (!this.form.startDate) {
          var s = new Date();
          s.setDate(1);
          this.form.startDate = s.toISOString().substr(0, 10);
        }

        if (!this.form.endDate) {
          var e = new Date();
          var year = new Date().getFullYear();
          var month = new Date().getMonth();
          month = month == 12 ? 0 : month + 1;
          var day = new Date(year, month, 0).getDate();
          e.setDate(day);
          this.form.endDate = e.toISOString().substr(0, 10);
        }

        this.getDataFromApi().then((data) => {
          this.datatable.total = data.total;
          this.datatable.items = data.items;
        });
      },
      deep: true,
    },
    "form.birthDate": {
      handler() {
        this.form.birthDateFormatted = this.formatDate(this.form.birthDate);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
